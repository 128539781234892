export default function BSN() {
    return (
        <div id="bsn" className="text-offwhite rounded-[4px] p-[32px] shadow-small bg-darkaccent mb-[32px]">
            <h2 className="text-offwhite border-b pb-[8px] font-bold text-[20px] leading-[1] mb-[16px]">BSN Segment</h2>
            <p className="text-offwhite mb-[16px]">To indicate the beginning of a shipping notice transaction set</p>
            <p className="text-fire font-bold leading-[1] mb-[16px] text-[18px]">Usage</p>
            <div className="text-offwhite grid grid-cols-[1fr_200px_1fr_1fr_1fr_1fr] gap-x-[16px] mb-[32px]">
                <div className="text-offwhite font-bold text-[12px] uppercase grid grid-cols-[1fr_200px_1fr_1fr_1fr_1fr] gap-x-[16px] col-span-full pb-[4px] mb-[8px] border-b border-darkgrey">
                    <div>Id</div>
                    <div>Segment Name</div>
                    <div>Req</div>
                    <div>Max Use</div>
                    <div>Loop</div>
                    <div>Notes</div>
                </div>
                <div className="text-offwhite grid grid-cols-[1fr_200px_1fr_1fr_1fr_1fr] gap-x-[16px] col-span-full pb-[4px] mb-[8px] border-b border-darkgrey">
                    <div>BEG</div>
                    <div>Beginning Segment for Shipping Notice</div>
                    <div>M</div>
                    <div>1</div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <p className="text-fire font-bold leading-[1] mb-[16px] text-[18px]">Elements</p>
            <div className="text-offwhite grid grid-cols-[1fr_1fr_200px_1fr_1fr_1fr_1fr_1fr] gap-x-[16px] mb-[32px]">
                <div className="text-offwhite font-bold text-[12px] uppercase grid grid-cols-[1fr_1fr_200px_1fr_1fr_1fr_1fr] gap-x-[16px] col-span-full pb-[4px] mb-[8px] border-b border-darkgrey">
                    <div>Ref</div>
                    <div>ID</div>
                    <div>Element Name</div>
                    <div>Req</div>
                    <div>Type</div>
                    <div>Min/max</div>
                    <div>Codes</div>
                </div>
                <div className="text-offwhite grid grid-cols-[1fr_1fr_200px_1fr_1fr_1fr_1fr] gap-x-[16px] col-span-full pb-[4px] mb-[8px] border-b border-darkgrey">
                    <div>BSN01</div>
                    <div>353</div>
                    <div>Transaction Set Purpose Code</div>
                    <div>M</div>
                    <div>ID</div>
                    <div>2/2</div>
                    <div>00</div>
                </div>
                <div className="text-offwhite grid grid-cols-[1fr_1fr_200px_1fr_1fr_1fr_1fr] gap-x-[16px] col-span-full pb-[4px] mb-[8px] border-b border-darkgrey">
                    <div>BSN02</div>
                    <div>396</div>
                    <div>Shipment Identification</div>
                    <div>M</div>
                    <div>AN</div>
                    <div>2/30</div>
                    <div></div>
                    <div className="col-span-full px-[8px] font-bold my-[8px]">Format should be: PONUMBER-SHIPMENTNUMBER. Example: 12345-1</div>
                </div>
                <div className="text-offwhite grid grid-cols-[1fr_1fr_200px_1fr_1fr_1fr_1fr] gap-x-[16px] col-span-full pb-[4px] mb-[8px] border-b border-darkgrey">
                    <div>BSN03</div>
                    <div>373</div>
                    <div>Date</div>
                    <div>M</div>
                    <div>DT</div>
                    <div>8/8</div>
                    <div></div>
                </div>
                <div className="text-offwhite grid grid-cols-[1fr_1fr_200px_1fr_1fr_1fr_1fr] gap-x-[16px] col-span-full pb-[4px] mb-[8px] border-b border-darkgrey">
                    <div>BSN04</div>
                    <div>337</div>
                    <div>Time</div>
                    <div>M</div>
                    <div>TM</div>
                    <div>4/4</div>
                    <div></div>
                </div>
                <div className="text-offwhite grid grid-cols-[1fr_1fr_200px_1fr_1fr_1fr_1fr] gap-x-[16px] col-span-full pb-[4px] mb-[8px] border-b border-darkgrey">
                    <div>BSN05</div>
                    <div>1005</div>
                    <div>Hierarchical Structure Code</div>
                    <div>O</div>
                    <div>ID</div>
                    <div>4/4</div>
                    <div>0001</div>
                    <div className="col-span-full px-[8px] font-bold my-[8px]">Shipment -&gt; Order -&gt; Pack -&gt; Item</div>
                </div>
            </div>
        </div>
    )
}